<template>
  <section class="views">
    <headers select="6"></headers>
    <div class="views_content">
      <div class="views_banner">
        <img
          :src="require('@/assets/image/hodgepodge/h5banner_team.jpg')"
          alt=""
        />
      </div>

      <div class="views_uls">
        <div class="back_box_uls">
          <div class="backUls_box">
            <div class="ulstitle">
              <img
                :src="require('@/assets/image/hodgepodge/title1.png')"
                alt=""
              />
            </div>

            <div class="uls_list">
              <!-- v-if="false" -->
              <div class="organizersMessage">
                <img :src="require('@/assets/image/xiao/t2.png')" alt="" />
                <img :src="require('@/assets/image/xiao/t1.png')" alt="" />
                <img :src="require('@/assets/image/xiao/t4.png')" alt="" />
                <img :src="require('@/assets/image/xiao/t3.png')" alt="" />
              </div>

              <div class="posters_text">
                <ul>
                  <li>
                    <div class="posters_text_left">贊助機構：</div>
                    <div class="posters_text_right">曾憲備慈善基金</div>
                  </li>
                  <li>
                    <div class="posters_text_left">主辦機構：</div>
                    <div class="posters_text_right">
                      曾憲備慈善基金、新界校長會、中文路（香港）有限公司
                    </div>
                  </li>
                  <li>
                    <div class="posters_text_left">合辦機構：</div>
                    <div class="posters_text_right">
                      民建聯及郭玲麗立法會議員辦事處、活力慈善基金、梨木樹苗趣社
                    </div>
                  </li>
                  <li>
                    <div class="posters_text_left">承辦機構：</div>
                    <div class="posters_text_right">中文路（香港）有限公司</div>
                  </li>
                  <li>
                    <div class="posters_text_left">支持機構：</div>
                    <div class="posters_text_right">
                      九龍地域校長聯會、香港島校長聯會、文冬漢語普通話中心、北京廣播電視台北京廣播影視培訓中心、朱偉林區議員辦事處、劉松剛區議員辦事處、李漢祥區議員辦事處、邱少雄區議員辦事處、陳孟宜區議員辦事處、林翠玲區議員辦事處、周劍豪區議員辦事處、王淑芬區議員辦事處、李細燕區議員辦事處
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="backUls_box2" ref="timeSlide">
            <div class="timeBox">
              <div
                class="timeBox_list"
                v-for="(item, index) in tap"
                :key="index"
                :ref="`tab${index}Ref`"
              >
                <div
                  v-if="index === xuanSelect"
                  style="display: flex"
                  id="target"
                >
                  <span class="selectTime" @click="tabBut(index)"
                    >當前階段</span
                  >
                </div>

                <strong v-else>
                  <span
                    :class="select == index ? 'select' : 'selectdefault'"
                    class="timeBox_dian"
                    @click="tabBut(index)"
                  ></span>
                </strong>

                <div
                  class="timeBox_top"
                  :class="index != select ? '' : 'timeBox_topselect'"
                  @click="tabBut(index)"
                >
                  <span v-if="index != select"> {{ item.name }} </span>
                  <img
                    :src="
                      require('@/assets/image/qp_2/' + (index + 1) + '.png')
                    "
                    alt=""
                    v-else
                  />
                </div>

                <div class="timeBox_bottom" @click="tabBut(index)">
                  <strong v-html="item.tiem"> </strong>
                </div>
              </div>
            </div>
          </div>

          <div class="backUls_box3">
            <ul>
              <li
                v-for="(item, index) in tap"
                :key="index"
                v-if="index == select"
              >
                <div class="backUls_box3_list">
                  {{ item.content.title }}
                </div>
                <div
                  class="backUls_box3_list2"
                  v-html="item.content.value"
                ></div>
                <!-- 報名尚未開始 -->
                <!-- 在線報名 -->
                <!-- <div
                  class="backUls_box3_list3"
                  v-if="xuanSelect == select"
                  @click="baoming()"
                >
                  在線報名
                </div> -->
                <!-- <div class="backUls_box3_list3" v-if="index == 0">報名結束</div> -->
              </li>
            </ul>
          </div>

          <!-- <div class="backUls_box4">
            <div class="backUls_box4_content">
              <div class="ulstitle">
                <img
                  :src="require('@/assets/image/hodgepodge/title2.png')"
                  alt=""
                />
              </div>
              <div class="backUls_box4_nr">
                <div class="backUls_box4_nr_list">
                  <ul>
                    <li>
                      參賽者為 2023-2024
                      學年在獲得香港教育局承認的中、小學（含特殊學校）就讀全日制或半日制之學生。
                    </li>
                    <li>
                      參賽者為 2023-2024
                      學年在粵港澳大灣區就讀中、小學且持有香港身份證的學生。
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->
          <!-- 參賽須知 -->
          <div class="backUls_box5">
            <div class="backUls_box5_content">
              <div class="ulstitle">
                <img
                  :src="require('@/assets/image/hodgepodge/title5.png')"
                  alt=""
                />
              </div>
              <div class="backUls_box5_nr">
                <div class="backUls_box5_nr_title">報名須知</div>
                <div class="backUls_box5_nr_content_2">
                  <ul>
                    <li>
                      中、小學組團體賽各組最多30隊參賽，幼稚園組團體賽最多20隊參賽，名額有限，先到先得。
                    </li>
                    <li>非牟利機構可根據組別組織學生報名參賽。</li>
                    <li>
                      各學校/機構最多可報名2隊參賽，每隊人數最少5人，最多30人，參賽隊伍之個別成員只可參與其中一隊的比賽。
                    </li>
                    <li>
                      參賽隊伍之成員允許越級挑戰，以該隊學生的<span
                        style="color: #ff0000"
                        >最高年級</span
                      >為標準選擇對應組別，如：A隊有6位參賽成員，其中2位為小學二年級學生，4位為幼稚園學生，須報名小學初級組。
                    </li>
                    <li>
                      參賽隊伍之成員為2024學年在獲得香港教育局承認的中、小學及幼稚園就讀全日制或半日制之學生或在粵港澳大灣區就讀中、小學及幼稚園且持有香港身份證的學生。
                    </li>
                  </ul>
                </div>

                <div class="backUls_box5_nr_title">參賽組別</div>
                <div
                  class="tables"
                  style="margin-bottom: 24px; padding: 0 10px"
                >
                  <table>
                    <tbody>
                      <tr>
                        <td>幼稚園</td>
                        <td>幼稚園組（K1 至 K3）</td>
                      </tr>
                      <tr>
                        <td rowspan="2">小學</td>
                        <td>小學初級組（P1 至 P3）</td>
                      </tr>
                      <tr>
                        <td>小學高級組（P4 至 P6）</td>
                      </tr>
                      <tr>
                        <td>中學</td>
                        <td>中學組（S1 至 S6）</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="backUls_box5_nr_title">報名確認</div>
                <div class="backUls_box5_nr_content_2">
                  <ul>
                    <li>
                      當中文路承辦方收妥報名表並審核無誤後，3個工作日內便會向參賽隊伍發出報名成功確認電郵。隊伍聯絡人或指導老師應細閱電郵內的一切注意事項。
                    </li>
                    <li>
                      凡是報名比賽的參賽隊伍，等同於同意主辦方將其參賽影片使用在不同管道媒體進行展示宣傳。
                    </li>
                  </ul>
                </div>

                <!-- <div class="backUls_box5_nr_title">注意事項</div>
                <div class="backUls_box5_nr_content_2">
                  <ul>
                    <li>
                      凡是報名比賽的參賽隊伍，等同於同意主辦方將其參賽影片使用在不同管道媒體進行展示宣傳。
                    </li>
                  </ul>
                </div> -->
                <div class="backUls_box5_nr_title">比賽章程</div>
                <div class="backUls_box5_nr_content_2">
                  <ul>
                    <li>詳細的「比賽章程」請點擊下方鏈接</li>
                  </ul>
                </div>

                <div class="backUls_box5_nr_content_2">
                  <div class="a_view" @click="zhangcheng()">
                    點擊下載比賽章程
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 比赛详情  -->
          <div class="backUls_box5">
            <div class="backUls_box5_content">
              <div class="ulstitle">
                <img
                  :src="require('@/assets/image/hodgepodge/title4.png')"
                  alt=""
                />
              </div>
              <div class="backUls_box5_nr">
                <div class="backUls_box5_nr_title">比賽誦材</div>
                <div class="backUls_box5_nr_content_2">
                  <ul>
                    <li>
                      比賽官方將提供推薦篇目，以供參賽隊伍選用，誦材篇目選自新詩、散文、古詩等。
                    </li>
                    <li>
                      自選誦材：誦材主題必須符合中華經典美文，例如古典或現代詩詞等，並歡迎各參賽隊伍以不同的創新方式演繹。<br />注：自選誦材內容須依時提交至官方進行審核。
                    </li>
                    <li>
                      初賽、總決賽可選用同一篇誦材，表演總時長應在6分鐘內，最長不超過6分鐘。<br />詳細的「比賽推薦誦材篇目」請點擊下方鏈接
                    </li>
                  </ul>

                  <div class="a_view" @click="socai()">點擊下載比賽誦材</div>
                </div>
                <div class="backUls_box5_nr_title">評分標準</div>
                <div class="backUls_box5_nr_content_2">
                  <div class="tables">
                    <table>
                      <thead>
                        <tr>
                          <th style="width: 30%">
                            <strong>評判標準</strong>
                          </th>
                          <th style="width: 70%">
                            <strong>內容説明</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td rowspan="2">
                            <strong>語音（20分）</strong>
                          </td>
                          <td style="text-align: left">
                            <strong
                              >普通話發音（10分）：聲母、韻母、聲調、吐字清晰。每處錯誤扣0.5或1分。
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left">
                            <strong
                              >節奏韻律（10分）：語速恰當、斷句準確、停連得當。每處錯誤扣0.5或1分。
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td rowspan="2">
                            <strong>合作性（15分）</strong>
                          </td>
                          <td style="text-align: left">
                            <strong
                              >服裝整齊、精神飽滿、舉止從容大方（5分）
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left">
                            <strong
                              >聲音整齊度（10分）：領誦、聲部間及合誦等接連流暢自然而有默契。</strong
                            >
                          </td>
                        </tr>
                        <tr>
                          <td rowspan="4">
                            <strong>誦材處理（55分）</strong>
                          </td>
                          <td style="text-align: left">
                            <strong
                              >肢體語言（5分）：配以適當的動作和手勢，舒展自然，不過分誇張。
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left">
                            <strong
                              >感情處理（25分）：富有感情、有感染力、充分表達誦材的情感、抑揚頓挫、添加重音渲染氣氛。
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left">
                            <strong
                              >表現力（15分）：眼神、表情準確自然、活潑生動，富有張力並符合文意。
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left">
                            <strong
                              >内容把握（10分）：基調準確，傳達正確主旨，語調或語氣運用配合誦材內容的態度或感情。
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td rowspan="3">
                            <strong>創新（10分）</strong>
                          </td>
                          <td style="text-align: left">
                            <strong
                              >聲音變化：聲部組合、停頓、節奏轉換等有出乎意料之外，合乎情理之中的處理方法，聽覺上予人新鮮感。
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left">
                            <strong
                              >配樂合理：不影響朗誦前提下，配以恰當背景音樂。
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left">
                            <strong>
                              隊型設計：畫面美觀，安排合理，有效暗示或反映誦材的重要內容。
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    style="
                      margin-top: 10px;
                      line-height: 25px;
                      text-align: left;
                      font-size: 0.28rem;
                      font-family: PingFang SC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #e80202;
                    "
                  >
                    *最終評分解釋權為比賽評審團所屬。
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 阶段 -->
          <div class="backUls_box5">
            <div class="backUls_box5_content">
              <div class="ulstitle">
                <img
                  :src="require('@/assets/image/hodgepodge/title3.png')"
                  alt=""
                />
              </div>
              <div class="backUls_box5_nr">
                <div class="backUls_box5_nr_title">第一階段：初賽</div>
                <div class="backUls_box5_nr_content">
                  <span style="color: red">初賽</span
                  >階段，中、小學及幼稚園各組別隊伍可使用官方推薦篇目或自選誦材進行集誦影片錄製，各參賽隊伍演出時間上限為
                  6 分鐘，逾時將會被扣分。演出時間將按提交的影片時間計算。
                  <div style="text-indent: 2em">
                    所有提交初賽影片的隊伍將獲發電子參賽證書。
                  </div>
                  <div style="text-indent: 2em">
                    各組別分數排名<span style="color: red">前3隊</span
                    >將獲入圍現場總決賽資格，若出現同分同名次情況，可同時進入總決賽。
                  </div>
                </div>

                <div class="backUls_box5_nr_title">
                  第二階段：總決賽暨頒獎典禮
                </div>
                <div class="backUls_box5_nr_content">
                  <span style="color: red">總決賽共12隊。總決賽為現場對決，</span
                  >各組別隊伍可使用官方推薦篇目或自選誦材進行現場朗誦，演出時間上限為
                  6 分鐘，逾時將會被扣分。<span style="color: red"
                    >現場將同步錄製對決比賽過程。</span
                  >
                  <div style="text-indent: 2em">
                    總決賽將評出各組別金獎1隊、銀獎1隊、銅獎1隊。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 參賽獎項 -->
          <div class="backUls_box5">
            <div class="backUls_box5_content">
              <div class="ulstitle">
                <img
                  :src="require('@/assets/image/hodgepodge/title6.png')"
                  alt=""
                />
              </div>

              <div class="tables">
                <table>
                  <thead>
                    <tr>
                      <th style="width: 18%">
                        <strong>比賽階段</strong>
                      </th>
                      <th style="width: 25%">
                        <strong>獎項</strong>
                      </th>
                      <th>
                        <strong>獎品內容</strong>
                      </th>
                      <th style="width: 18%">
                        <strong>選拔方式 </strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowspan="3">
                        <strong>初賽階段</strong>
                      </td>
                      <td>
                        <strong>隊伍參賽證明 </strong>
                      </td>
                      <td>
                        <strong>電子-參賽證明 </strong>
                      </td>
                      <td rowspan="2">
                        <strong>提交影片 </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>指導老師感謝狀 </strong>
                      </td>
                      <td>
                        <strong>電子-感謝狀 </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>隊伍入圍證明 </strong>
                      </td>
                      <td>
                        <strong>電子-入圍證明 </strong>
                      </td>
                      <td>
                        <strong
                          >各組別分數排名<span
                            style="color: #e80202; font-weight: 600"
                            >前3隊</span
                          >入圍決賽
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="3">
                        <strong>總決賽階段 </strong>
                      </td>
                      <td>
                        <strong>金獎 <br />（1隊） </strong>
                      </td>
                      <td style="text-align: left">
                        ① 獎金2,000 HKD<br />② 所屬團體學校/機構獎盃及證書；<br />③
                        金獎優秀指導老師證書；<br />
                        ④ 所屬隊伍之學生參賽證明。
                      </td>

                      <td rowspan="3">
                        <strong>現場比賽 </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>銀獎 <br />（1隊） </strong>
                      </td>
                      <td style="text-align: left">
                        ① 獎金1,000 HKD<br />② 所屬團體學校/機構獎盃及證書；
                        <br />③ 銀獎優秀指導老師證書； <br />④
                        所屬隊伍之學生參賽證明。
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>銅獎 <br />（1隊） </strong>
                      </td>
                      <td style="text-align: left">
                        ① 獎金800 HKD
                        <br />② 所屬團體學校/機構獎盃及證書； <br />③
                        銅獎優秀指導老師證書； <br />④ 所屬隊伍之學生參賽證明。
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  style="
                    margin-top: 20px;
                    line-height: 25px;
                    text-align: left;
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #e80202;
                  "
                >
                  *主辦方保留對比賽流程安排及比賽相關章程的最終解釋權。如有任何爭議，主辦方保留一切最終決定權。
                </div>
              </div>
            </div>
          </div>

          <!-- 占位 -->
          <div class="backUls_box6"></div>
        </div>
      </div>

      <!-- <div class="fontBottom">
        <span>請用電腦打開網頁進行報名</span>
      </div> -->
      <!-- <div class="fontBottom" @click="baoming()">
        <span>在線報名</span>
      </div> -->
      <!-- <img :src="require('@/assets/image/hodgepodge/anniu.png')" alt="" />
      <div class="fontBottom" @click="handleUploadVideo()">
        <span>上載複賽影片</span>
      </div> -->
      <!-- <<div class="fontBottom">
        <span>複賽結束</span>
      </div> -->
    </div>
    <!-- 复赛资料视频上传-->
    <van-popup
      v-model="datumZZC"
      :close-on-click-overlay="false"
      :closeable="true"
      :round="true"
      :lock-scroll="false"
    >
      <div class="datumBox">
        <div class="titleH1">
          <div class="left_icon">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="title">上載參賽影片</div>
          <div class="right_icon">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div class="stepBox">
          <div class="stepUls">
            <div
              class="xian"
              :class="stepActive >= 1 ? 'active2' : 'active'"
            ></div>
            <div
              class="xian"
              :class="stepActive >= 2 ? 'active2' : 'active'"
            ></div>
          </div>

          <ul>
            <li
              v-if="chooseFrom == 0"
              v-for="(item, index) in 3"
              :class="stepActive >= index ? 'active' : ''"
              :key="index"
            ></li>
            <li
              v-if="chooseFrom == 1"
              v-for="(item, index) in 2"
              :class="stepActive >= index ? 'active' : ''"
              :key="index"
            ></li>
          </ul>
        </div>
        <div class="stepText">
          <div :class="stepActive >= 0 ? 'active' : ''">登錄</div>
          <div v-if="chooseFrom == 0" :class="stepActive >= 1 ? 'active' : ''">
            完善資料
          </div>
          <div :class="stepActive >= 2 ? 'active' : ''">上載影片</div>
        </div>

        <div class="datumCentent" v-if="stepActive == 0">
          <div class="tab">
            <div class="prompt" style="margin-bottom: 12px">
              <div
                :class="chooseFrom == 0 ? 'highlight' : ''"
                @click="datumYZ(0)"
              >
                個人報名
              </div>
              <div
                :class="chooseFrom == 1 ? 'highlight' : ''"
                @click="datumYZ(1)"
              >
                學校報名
              </div>
            </div>
            <template v-if="chooseFrom == 0">
              <div class="row-input">
                <!-- <div class="input-name" style="width: 22%; padding: 0">
                  參賽電話
                </div> -->
                <div class="promptInput2">
                  <div class="lis">
                    <div class="lisLife">
                      <!-- <img :src="require('@/assets/image/hk.png')" />
									<span>+852</span> -->
                      <CountryCodeSelector
                        :searchbar="false"
                        :countryCode.sync="prefix"
                        style="width: 90px; margin-right: 15px"
                      ></CountryCodeSelector>
                    </div>
                    <div class="lisRight">
                      <input
                        type="text"
                        placeholder="請輸入參賽電話"
                        value=""
                        v-model.trim="from2.phone"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row-input">
                <!-- <div class="input-name" style="width: 22%; padding: 0">
                  參賽姓名
                </div> -->
                <div class="promptInput">
                  <input placeholder="請輸入參賽者姓名" v-model="from2.name" />
                </div>
              </div>
            </template>
            <template v-if="chooseFrom == 1">
              <div class="row-input">
                <!-- <div class="input-name">學校編號</div> -->
                <div class="promptInput">
                  <input
                    placeholder="請輸入學校編號"
                    v-model.trim="from2.schoolCode"
                  />
                </div>
              </div>
              <div class="row-input">
                <!-- <div class="input-name">學生編號</div> -->
                <div class="promptInput">
                  <input
                    placeholder="請輸入學生編號"
                    v-model.trim="from2.studentCode"
                  />
                </div>
              </div>
              <div class="row-input">
                <!-- <div class="input-name">學生密碼</div> -->
                <div class="promptInput">
                  <input
                    placeholder="請輸入學生密碼"
                    v-model.trim="from2.password"
                  />
                </div>
              </div>
            </template>
          </div>
          <div class="tabTis_1">
            提交遇到問題？點擊右側按鈕聯系我們
            <a href="http://wa.me/85291496563" target="_blank" @click="lianxi"
              ><img src="@/assets/image/fusai/whatsapp.png"
            /></a>
          </div>
          <div class="view_guide" @click="isRematch = true">查看複賽指引</div>
          <div class="but" @click="datumActiveBut ? datumBut(0) : ''">
            下一步
          </div>
        </div>

        <div class="datumCentent" v-if="stepActive == 1">
          <div class="tab">
            <div class="prompt" v-show="schollName == null">就讀學校</div>
            <div
              class="promptInput"
              style="width: 100%"
              v-show="schollName == null"
            >
              <input placeholder="請輸入就讀學校" v-model="from2.school_name" />
            </div>
            <div class="prompt">
              證件上傳
              <span>&nbsp;帶照片學生證/身份證明</span>
            </div>
            <div class="promptInput3">
              <uploaderCent
                @uploaderChange="uploaderImageChange"
                accept="image/*"
                uploadText="點擊上傳"
                logoaAtive="0"
              ></uploaderCent>
            </div>
          </div>

          <div class="tabTis">
            提交遇到問題？點擊右側按鈕聯系我們
            <a href="http://wa.me/85291496563" target="_blank" @click="lianxi"
              ><img src="@/assets/image/fusai/whatsapp.png"
            /></a>
          </div>
          <div class="but" @click="datumBut(1)">下一步</div>
          <!-- <div class="but2" @click="getBack()">返回上一步</div> -->
        </div>

        <div class="datumCentent" v-if="stepActive == 2">
          <div class="tab">
            <div class="promptInput3">
              <uploaderCent
                @uploaderChange="uploaderVideoChange"
                accept="video/*"
                uploadText="點擊上載影片"
                v-if="wonderful == false"
                logoaAtive="1"
              ></uploaderCent>
              <!-- 上傳影片成功后顯示預覽按鈕 -->
              <div class="videoYulan" v-if="videoYL">
                <div class="guanbu" @click="videoYLBut">X</div>
                <video controls :autoplay="true" :src="videoUrl"></video>
              </div>
              <div
                class="videoYulan2"
                style="cursor: pointer"
                v-if="from2.video != null && wonderful == false"
                @click="videoYLBut"
              >
                <span style="color: #000000 !important; margin-right: 10px"
                  >影片已成功上載</span
                >點擊預覽視頻
              </div>
              <!-- 點擊預覽視頻  @click="videoYLBut"-->
              <!-- <div class="videoYulan2" v-if="from2.video && wonderful == false" @click="videoYLBut">影片已成功上載</div>

							<!-- 提交成功 -->
              <div class="wonderful" v-if="wonderful == true">
                <img src="@/assets/image/fusai/ludui.png" />
                恭喜你，成功上載影片，請耐心等待評審結果
              </div>
            </div>
          </div>
          <div class="tabTis" v-if="wonderful == false">
            提交遇到問題？點擊右側按鈕聯系我們
            <a href="http://wa.me/85291496563" target="_blank" @click="lianxi"
              ><img src="@/assets/image/fusai/whatsapp.png"
            /></a>
          </div>
          <div
            class="but"
            v-if="wonderful == false"
            @click="datumActiveBut ? submit() : ''"
          >
            提交
          </div>
          <div class="but" v-if="wonderful == true" @click="wonderfulBut">
            我知道了
          </div>
          <!-- <div class="but2" v-if="wonderful==false" @click="getBack()">返回上一步</div> -->
        </div>
      </div>
    </van-popup>
    <!-- 未入圍/成功入園 -->
    <van-popup v-model="newShortZZC" :round="true" :lock-scroll="false">
      <div class="newShortBox">
        <div class="list" v-if="newShortActive == 1">
          <!-- <div class="lis lisCenten"> <van-image width="149px" :src="require('@/assets/image/fusai/yes.png')" /> </div> -->
          <div class="ulst">
            <ul>
              <li>姓名</li>
              <li>組別</li>
              <li>分數</li>
            </ul>
            <ul>
              <li>
                {{ userObj.studentName == null ? '' : userObj.studentName }}
              </li>
              <li>
                {{
                  userObj.competitionGroup == null
                    ? ''
                    : userObj.competitionGroup
                }}
              </li>
              <li>{{ userObj.score == null ? '' : userObj.score }}</li>
            </ul>
          </div>
          <div class="lis lisText">
            恭喜您，成功入圍複賽，請仔細閱讀入圍電郵，上載參賽視頻。
          </div>
          <!-- <div class="but" @click="handelGuide">點擊查看複賽指引</div> -->
        </div>

        <div class="list" v-if="newShortActive == 2">
          <div class="ulst">
            <ul>
              <li>姓名</li>
              <li>組別</li>
              <li>分數</li>
            </ul>
            <ul>
              <li>
                {{ userObj.studentName == null ? '' : userObj.studentName }}
              </li>
              <li>
                {{
                  userObj.competitionGroup == null
                    ? ''
                    : userObj.competitionGroup
                }}
              </li>
              <li>{{ userObj.score == null ? '' : userObj.score }}</li>
            </ul>
          </div>
          <div class="lis lisText">
            很遺憾，您的成績未能入圍複賽，非常感謝您的參與
          </div>
        </div>
      </div>
    </van-popup>
    <van-dialog
      v-model="showGuide"
      :show-close-button="false"
      :close-on-click-overlay="true"
      width="80%"
      z-index="99999"
      confirm-button-text="關 閉"
    >
      <div class="image-container" v-if="showType == 'img'">
        <van-image
          width="100%"
          :src="require('@/assets/image/H5_guide.jpg')"
          fit="contain"
        />
      </div>
      <div class="image-container" v-if="showType == 'video'">
        <video
          height="99%"
          controls
          :autoplay="true"
          src="https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/4195fb8150d8a227adbfdb6f85d132d0.mp4"
        ></video>
      </div>
    </van-dialog>
    <!-- 複賽指引 -->
    <RematchModal
      style="z-index: 9999"
      v-if="isRematch"
      @cancel="isRematch = false"
    ></RematchModal>
  </section>
</template>

<script>
import headers from '@/components/header/h_header'
import publics from '@/mixin/public_mixin'
import home from '@/mixin/home_mixin'
import uploaderCent from '@/components/uploaderBox/index.vue'
import CountryCodeSelector from '@/components/CountryCodeSelector'
import RematchModal from '@/components/rematchModal/index.vue'
export default {
  name: 'views',
  data() {
    return {
      tap: [
        {
          name: '报名及初賽',
          tiem: `7月1日<br/> 至 <br/>9月30日 22:00`,
          select: false,
          xuanSelect: false,
          content: {
            title: '官方活動網站',
            value:
              '在官方活動網站提交報名參賽隊伍的總人數、選擇的誦材篇章篇目，並提交初賽影片。'
          }
        },
        {
          name: '公佈總決賽入圍名單',
          tiem: `10月7日`,
          select: false,
          xuanSelect: false,
          content: {
            title: '官方活動網站公佈入圍隊伍名單',
            value: `請留意電郵或短信提醒並查閱入圍結果。`
          }
        },
        {
          name: '總決賽暨頒獎典禮',
          tiem: `10月26日`,
          select: false,
          xuanSelect: false,
          content: {
            title: '（請以主辦方最新公佈的安排為準）',
            value: '將於線下場所進行現場對決比賽，上半場決賽，下半場頒獎典禮。'
          }
        }
      ],
      select: 1,
      xuanSelect: 1, //当前阶段
      datumZZC: false, // 上传视频弹窗控制
      stepActive: 0, //控制流程  很重要这玩意勿搞错  参数0 1 2  表示三步骤
      datumActiveBut: true, //防止連續點擊
      wonderful: false, //成功
      videoYL: false, //預覽
      chooseFrom: 0, //驗證
      from2: {
        name: null, //名字
        school_name: null, //学校
        email: null, //邮箱
        phone: null, //手机
        certificate: null, //证件
        video: null, //视频
        schoolCode: null,
        studentCode: null,
        password: null
      },
      schollName: null, //控制学校是否显示
      newShortZZC: false, //入圍 彈窗
      newShortActive: 1, //1是成功  2是失敗
      choose: 0, //查詢的下標
      from: {
        school_name: null,
        email: null,
        phone: null
      },
      finalistsBut: true, //防止連續點擊
      userObj: {
        competitionGroup: 1, //組別
        score: null, //分數
        studentName: null //名字
      },
      prefix: '852',
      showGuide: false,
      showType: '',
      isRematch: false
    }
  },
  computed: {},
  components: {
    headers,
    uploaderCent,
    CountryCodeSelector,
    RematchModal
  },
  mixins: [publics, home],
  watch: {},
  created() {},
  mounted() {
    if (this.$route.query.uploadVideo) {
      this.datumZZC = true
      this.$router.replace({ query: { uploadVideo: false } })
    }
    this.$nextTick(() => {
      setTimeout(() => {
        this.initScroll() // 时间轴居中
      }, 1500)
    })
  },
  methods: {
    handleShowGuide(type) {
      this.showGuide = true
      this.showType = type
    },
    handleUploadVideo() {
      this.datumZZC = true
    },
    datumYZ(e) {
      this.chooseFrom = e
    },
    lianxi() {
      // 联系我们
      // window.location.href = 'http://wa.me/85291496563';
      window.open('http://wa.me/85291496563')
    },
    // 點擊上傳下一步
    datumBut(e) {
      switch (e) {
        case 0:
          // 下一步 驗證信息
          this.getUser()
          // this.stepActive = 2;
          break
        case 1:
          // this.$toast.clear()
          // 直接下
          if (this.from2.school_name == null || this.from2.school_name == '') {
            this.$toast('请输入就讀學校 !')
            return
          }

          if (this.from2.certificate == null || this.from2.certificate == '') {
            this.$toast('请上傳證件 !')
            return
          }

          this.stepActive = 2
          break
        case 2:
          // 提交啦
          break
        case 3:
      }
    },
    // 驗證信息
    getUser() {
      let params
      if (this.chooseFrom == 0) {
        params = {
          studentName: this.from2.name,
          prefix: this.prefix,
          phone: this.from2.phone,
          email: null
        }
        if (params.studentName == '' || params.studentName == null) {
          this.$toast('請輸入您的姓名 !')
          return
        }
      } else if (this.chooseFrom == 1) {
        const { schoolCode, studentCode, password } = this.from2
        // 判断三个参数不能为空
        if (schoolCode == '' || schoolCode == null) {
          this.$toast('請輸入學校編號 !')
          return
        }
        if (studentCode == '' || studentCode == null) {
          this.$toast('請輸入學生編號 !')
          return
        }
        if (password == '' || password == null) {
          this.$toast('請輸入學生密碼 !')
          return
        }
        params = {
          schoolCode,
          studentCode,
          password
        }
      }

      if (this.chooseFrom == 0) {
        // 手機
        if (params.phone == '' || params.phone == null) {
          this.$toast('手機號格式有誤 !')
          return
        }

        if (!this.validator_phone(params.phone)) {
          this.$toast('手機號格式有誤 !')
          return
        }
        // } else if (this.chooseFrom == 1) {
        //   // 郵箱
        //   if (!this.validator_email(params.email)) {
        //     this.$toast('電郵格式有誤 !')
        //     return
        //   }
      }
      // console.log('上传:', params);
      this.datumActiveBut = false
      this.$toast.loading()
      this.$axios
        .post(this.$api.getPersonInfo, params)
        .then((response) => {
          this.$toast.clear()
          this.datumActiveBut = true
          // console.log(response);
          if (response.data.code == 500) {
            this.$toast.fail(response.data.msg)
          }
          if (response.data.code == 0) {
            const {
              school,
              shortlisted,
              studentName,
              score,
              competitionGroup
            } = response.data.data
            this.userObj.competitionGroup = competitionGroup //組別
            this.userObj.score = score //分數
            this.userObj.studentName = studentName //名字

            if (shortlisted) {
              this.from2.school_name = school
              this.schollName = school == '' ? null : school
              // 成功
              if (this.chooseFrom == 0) {
                this.stepActive = 1
              } else {
                this.stepActive = 2
              }
            } else {
              // 失敗
              this.datumZZC = false //复赛资料上传弹窗
              this.stepActive = 0 //控制流程  很重要这玩意勿搞错  参数0 1 2  表示三步骤
              this.newShortZZC = true //入圍 彈窗
              this.newShortActive = 2 //1是成功  2是失敗
            }
          }
        })
        .catch((error) => {
          this.$toast.clear()
          this.datumActiveBut = true
          //请求失败
          this.$toast.fail('error')
          console.log(error)
        })
    },
    validator_email(val) {
      const email = val.trim()
      return /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-\_]+\.)+[A-Za-z]{2,6}$/.test(
        email
      )
    },
    validator_phone(val) {
      const phone = val.trim()
      return /^[0-9]*$/.test(phone)
    },
    // 視頻
    uploaderVideoChange(e) {
      // console.log('视频', e);
      this.from2.video = e.vaidoUrl
      this.videoUrl = e.vaidoUrl
      // this.videoUrl = e.yuanVideo;
      this.$toast.clear()
    },
    // 圖像
    uploaderImageChange(e) {
      // console.log('图像', e);
      this.from2.certificate = e.vaidoUrl
      this.$toast.clear()
    },
    // 視頻預覽
    videoYLBut() {
      this.videoYL = !this.videoYL
    },
    //返回上一步
    // getBack() {
    // 	this.stepActive--;
    //   this.from2.video = null
    //   this.videoUrl = null
    // },
    // 提交
    submit() {
      const {
        phone,
        name,
        video,
        email,
        certificate,
        school_name,
        schoolCode,
        studentCode,
        password
      } = this.from2
      let params = {
        videoUrl: video, //学生上传的视频内容
        // email, //學生電郵
        personalID: certificate,
        currentSchool: school_name
      }
      if (this.chooseFrom == 1) {
        // 学校报名
        params.schoolCode = schoolCode
        params.studentCode = studentCode
        params.password = password
      } else {
        // 个人报名
        params.phone = phone
        params.studentName = name
      }

      if (params.videoUrl == null || params.videoUrl == '') {
        this.$toast('請上傳參賽作品 !')
        return
      }

      this.datumActiveBut = false
      this.$toast.loading({
        message: '正在提交中...',
        duration: 0,
        forbidClick: true
      })

      // 本地測試專用
      // this.$axios({
      // 	url: '/api/statistics/competition/update',
      // 	method: 'post',
      // 	data: params
      // })

      // 正式發包
      this.$axios
        .post(this.$api.update, params)
        .then((response) => {
          this.datumActiveBut = true
          // console.log(response);
          if (response.data.code == 500) {
            this.$toast.fail(response.data.msg)
          }
          if (response.data.code == 0) {
            this.$toast.clear()
            this.wonderful = true
          }
        })
        .catch((error) => {
          this.datumActiveBut = true
          //请求失败
          this.$toast.fail('error')
          console.log(error)
        })
    },
    // 上傳成功  相當於初始化
    wonderfulBut() {
      this.wonderful = false
      this.datumZZC = false
      this.stepActive = 0
      let from = {
        name: null,
        school_name: null,
        email: null,
        phone: null,
        certificate: null,
        video: null
      }
      this.from2 = from
    },
    zhangcheng() {
      window.open(this.constitutionUrl_team, '_blank')

      // this.$toast.loading({
      // 	message: '獲取地址中...',
      // 	forbidClick: true,
      // 	duration: 0
      // });

      // let url = 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-07/fe62588d996ef0818389f65f4192086c.pdf';
      // this.$axios({
      //   url: url,
      //   method: 'get',
      //   responseType: 'blob',
      // })
      // .then(response => {
      //   this.$toast.clear();
      //   this.$toast.success('開始下載');
      //   // 将文件流转化为 Blob 对象，并创建 a 标签进行下载
      //   const blob = new Blob([response.data], { type: 'application/pdf' });
      //   const downloadElement = document.createElement('a');
      //   const href = window.URL.createObjectURL(blob); // 创建下载链接
      //   downloadElement.href = href;
      //   downloadElement.download = 'zhangcheng.pdf'; // 文件名
      //   document.body.appendChild(downloadElement);
      //   downloadElement.click(); // 下载
      //   document.body.removeChild(downloadElement); // 下载完成后移除元素
      //   window.URL.revokeObjectURL(href); // 释放占用的内存空间
      // }).catch(error => {
      //   this.$toast.clear();
      //   console.error(error); // 处理异常情况
      // });
    },
    baoming() {
      // this.$dialog
      //   .alert({
      //     title: '提示',
      //     message: '報名未開始',
      //     confirmButtonText: '確認'
      //   })
      //   .then(() => {
      //     // on close
      //   })
      this.$router.push('/signup_p')
    },
    socai() {
      this.$router.push('/material_team')
    },
    tabBut(e) {
      this.select = e
      // 触发滑动方法
      this.scrollLeftTo(e)
    },
    initScroll() {
      // let target = document.getElementById('target')
      const nav = this.$refs.timeSlide
      const title = this.$refs[`tab${this.xuanSelect}Ref`][0]
      // 计算位移偏差
      const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2
      nav.scrollLeft = to
    },
    // 滑动
    scrollLeftTo(name) {
      const ref = `tab${name}Ref`
      const nav = this.$refs.timeSlide
      // console.log("额:", this.$refs.timeSlide.offsetWidth);
      const title = this.$refs[ref][0]
      // 计算位移偏差
      const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2
      nav.scrollLeft = to
    }
  }
}
</script>

<style lang="scss" scoped>
.views {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(225deg, #ff8310 0%, #fe840f 100%);

  .views_content {
    width: 100%;
    height: calc(100% - 0.92rem);
    overflow-x: hidden;

    .views_banner {
      width: 100%;
      display: grid;

      img {
        width: 100%;
      }
    }

    .views_uls {
      width: 100%;
      background: url('~@/assets/image/hodgepodge/back.png') no-repeat;
      background-size: 100%;
      min-height: 5rem;

      .back_box_uls {
        width: 100%;

        .backUls_box {
          width: 100%;
          padding: 0.32rem;
          box-sizing: border-box;

          .ulstitle {
            width: 6.0228rem;
            height: 0.43rem;
            margin: auto;

            img {
              width: 100%;
              margin-top: -0.85rem;
            }
          }

          .uls_list {
            width: 100%;
            background: #ffffff;
            padding: 0.32rem;
            padding-bottom: 1rem;
            box-sizing: border-box;
            background: #ffffff;
            box-shadow: 0px 0.08rem 0.12rem 0px rgba(0, 0, 0, 0.05);
            border-radius: 0.3rem;
            position: relative;

            .organizersMessage {
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 0.2rem;

              img {
                width: 1.4rem;
                height: 0.6rem;
                background: #ffffff;
                border-radius: 0.24rem;
                object-fit: contain;
                box-shadow: 0px 0.08rem 0.08rem 0px rgba(0, 0, 0, 0.03);
              }
            }

            .posters_text {
              width: 100%;

              ul {
                width: 100%;

                li {
                  width: 100%;
                  margin-bottom: 0.16rem;
                  display: flex;
                  font-size: 0.24rem;
                  line-height: 0.4rem;

                  .posters_text_left {
                    width: 1.2rem;
                    font-family: PingFang SC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    text-align: left;
                  }

                  .posters_text_right {
                    width: calc(100% - 1.5rem);
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    text-align: left;
                  }
                }
              }
            }
          }
        }

        .backUls_box2::-webkit-scrollbar {
          display: none;
        }

        .backUls_box2 {
          width: 100%;
          min-height: 3rem;
          margin-top: -22%;
          background: url('~@/assets/image/hodgepodge/back2.png') no-repeat;
          background-size: 100%;
          position: relative;
          padding-top: 2.6rem;
          padding-bottom: 0.86rem;
          padding-left: 0.32rem;
          box-sizing: border-box;
          overflow-x: scroll;

          .timeBox {
            width: 100%;
            height: 0.08rem;
            // background: linear-gradient(270deg, rgba(255,255,255,0) 0%, #FFFFFF 22%, #FFFFFF 80%, rgba(255,255,255,0) 100%);
            border-radius: 0.52rem;
            white-space: nowrap;
            // display: flex;
            display: inline-flex;
            margin-bottom: 1rem;

            .timeBox_list {
              width: 2.54rem;
              height: 100%;
              background: #ffffff;
              position: relative;
              flex: 0 0 auto;
              display: flex;
              justify-content: center;
              align-items: center;

              .timeBox_dian {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                border: 0.04rem solid #ffffff;
                border-radius: 50%;
              }

              .select {
                width: 0.32rem;
                height: 0.32rem;
                background: #ffe600;
              }

              .selectdefault {
                width: 0.2rem;
                height: 0.2rem;
                background: #ffffff;
              }

              .selectTime {
                padding: 0.1rem 0.3rem;
                font-size: 0.24rem;
                text-align: center;
                box-sizing: border-box;
                background: #f78040;
                border-radius: 50px;
                border: 1px solid #fff;
                color: #fff;
              }

              .timeBox_topselect {
                // border-image-source: url("~@/assets/image/icon/qipao2.png");
                // border-image-slice: 116 76 76 76;
                // border-image-width: 1.1rem 0.8rem 0.8rem 0.8rem;
                // border-width: 0.8rem;
                // padding: 0.07rem 0.17rem 0.14rem 0.17rem;
                // box-sizing: border-box;
                // font-size: 0.28rem;
                // font-family: PingFang SC-Semibold, PingFang SC;
                // font-weight: 600;
                // color: #903300 !important;
                // align-items:center !important;
              }

              .timeBox_top {
                width: 100%;
                height: 1rem;
                // background-color: red;
                position: absolute;
                bottom: 0.4rem;
                left: 0;

                display: flex;
                justify-content: space-around;
                align-content: flex-end;
                flex-wrap: nowrap;
                align-items: flex-end;
                font-size: 0.28rem;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;

                img {
                  width: 100%;
                  object-fit: contain;
                }
              }

              .timeBox_bottom {
                width: 100%;
                height: 1rem;
                // background-color: red;
                position: absolute;
                top: 0.4rem;
                left: 0;

                display: flex;
                justify-content: space-around;
                font-size: 0.2rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 0.35rem;
              }
            }

            .timeBox_list:first-child {
              background: linear-gradient(
                270deg,
                #ffffff 80%,
                #ffffff 22%,
                #ffffff 80%,
                rgba(255, 255, 255, 0) 100%
              );
            }

            .timeBox_list:last-child {
              background: linear-gradient(
                270deg,
                rgba(255, 255, 255, 0) 0%,
                #ffffff 80%,
                #ffffff 80%,
                #ffffff 100%
              );
            }
          }
        }

        .backUls_box3 {
          width: 100%;
          background-color: #f78041;

          ul {
            width: 100%;
            display: flex;
            padding: 0.32rem;
            box-sizing: border-box;

            li {
              padding: 0.24rem;
              box-sizing: border-box;
              width: 100%;
              min-height: 2.72rem;
              background: #ffffff;
              box-shadow: 0px 0.08rem 0.12rem 0px rgba(0, 0, 0, 0.05);
              border-radius: 0.24rem;
              text-align: left;
              position: relative;

              .backUls_box3_list {
                width: 100%;
                font-size: 0.32rem;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #f97226;
                line-height: 0.43rem;
              }

              .backUls_box3_list2 {
                width: 100%;
                font-size: 0.28rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 0.28rem;
                margin-top: 0.08rem;
                line-height: 0.44rem;

                span {
                  font-size: 0.28rem;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 0.28rem;
                  margin-top: 0.08rem;
                  line-height: 0.44rem;
                }
              }

              .backUls_box3_list3 {
                position: absolute;
                bottom: 0.24rem;
                right: 0.24rem;
                padding: 0.08rem 0.24rem;
                box-sizing: border-box;
                border-radius: 1.6rem;
                font-size: 0.24rem;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                line-height: 28px;

                // background: url('~@/assets/image/hodgepodge/buttom.png')
                //   no-repeat;
                // background-size: 100% 100%;
                background: #d0d0d0;
                box-shadow: 0px 4px 9px 0px rgba(208, 208, 208, 0.4);
              }
            }
          }
        }

        .backUls_box4 {
          width: 100%;
          padding: 0.32rem;
          padding-top: 1.32rem;
          box-sizing: border-box;
          background: #ff782f;

          .backUls_box4_content {
            width: 100%;
            // height: 8.72rem;
            // background: url('~@/assets/image/hodgepodge/back3.png') no-repeat;
            background-size: 100%;
            width: 100%;
            padding: 0.32rem;
            padding-top: 0rem;
            box-sizing: border-box;
            border-image-source: url('~@/assets/image/hodgepodge/back3.png');
            border-image-slice: 84 10 10 10;
            border-image-width: 0.6rem 0.12rem 0.12rem 0.12rem;
            border-width: 0.12rem;

            .ulstitle {
              width: 6.0228rem;
              height: 0.43rem;
              margin: auto;

              img {
                width: 100%;
                margin-top: -0.85rem;
              }
            }

            .backUls_box4_nr {
              width: 100%;
              min-height: 3rem;
              background: #ffffff;
              padding: 0.32rem 0.24rem 0.48rem 0.24rem;
              box-sizing: border-box;
              background: #ffffff;
              box-shadow: 0px 0.08rem 0.12rem 0px rgba(0, 0, 0, 0.05);
              border-radius: 0.16rem;
              position: relative;

              .backUls_box4_nr_list {
                // font-size: 0.26rem;
                // font-family: PingFang SC-Semibold, PingFang SC;
                // font-weight: 600;
                // color: #f97226;
                // line-height: 0.44rem;
                // text-align: center;
                margin-bottom: 0.32rem;
                ul {
                  width: 100%;
                  padding-left: 0.48rem;
                  box-sizing: border-box;

                  li {
                    width: 100%;
                    font-size: 0.26rem;
                    font-family: PingFang SC-Semibold, PingFang SC;
                    color: #666666;
                    line-height: 0.44rem;
                    text-align: left;
                    list-style: disc;
                  }
                }
              }

              .backUls_box4_remarks {
                width: 100%;
                font-size: 0.26rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 0.44rem;
              }
            }
          }
        }

        .backUls_box5 {
          width: 100%;
          padding: 0.32rem;
          padding-top: 1.32rem;
          box-sizing: border-box;
          background: #ff782f;

          .backUls_box5_content {
            width: 100%;
            background-size: 100%;
            width: 100%;
            padding: 0.32rem;
            padding-top: 0rem;
            box-sizing: border-box;
            border-image-source: url('~@/assets/image/hodgepodge/back3.png');
            border-image-slice: 84 10 10 10;
            border-image-width: 0.6rem 0.12rem 0.12rem 0.12rem;
            border-width: 0.12rem;

            .ulstitle {
              width: 6.0228rem;
              height: 0.43rem;
              margin: auto;

              img {
                width: 100%;
                margin-top: -0.85rem;
              }
            }

            .backUls_box5_nr {
              width: 100%;
              min-height: 3rem;
              background: #ffffff;
              padding: 0.32rem 0.24rem 0.48rem 0.24rem;
              box-sizing: border-box;
              background: #ffffff;
              box-shadow: 0px 0.08rem 0.12rem 0px rgba(0, 0, 0, 0.05);
              border-radius: 0.16rem;
              position: relative;

              .backUls_box5_nr_title {
                padding: 0.14rem 0.66rem;
                box-sizing: border-box;
                background: #ff9003;
                box-shadow: 0px 0px 0.14rem 0px rgba(249, 118, 47, 0.3);
                border-radius: 0.62rem;
                border: 0.04rem solid #ffffff;
                font-size: 0.32rem;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                display: inline-block;
                margin-bottom: 0.32rem;
              }

              .backUls_box5_nr_content {
                text-indent: 2em;
                width: 100%;
                font-size: 0.28rem;
                font-family: PingFang SC-Semibold, PingFang SC;
                color: #666666;
                line-height: 0.48rem;
                text-align: left;
                margin-bottom: 0.48rem;

                span {
                  color: #f97226;
                  font-weight: 600;
                }
              }

              .backUls_box5_nr_content:last-child {
                margin-bottom: 0px;
              }

              .backUls_box5_nr_content_2 {
                width: 100%;

                ul {
                  width: 100%;
                  padding-left: 0.48rem;
                  box-sizing: border-box;

                  li {
                    width: 100%;
                    font-size: 0.28rem;
                    font-family: PingFang SC-Semibold, PingFang SC;
                    color: #666666;
                    line-height: 0.48rem;
                    text-align: left;
                    margin-bottom: 0.48rem;
                    list-style: disc;

                    span {
                      color: #f97226;
                      font-weight: 600;
                    }

                    font {
                      color: #e80202;
                      font-weight: 600;
                    }
                  }
                }

                .a_view {
                  text-align: center;
                  font-size: 0.28rem;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #e80202;
                  text-decoration: underline;
                }
              }

              .backUls_box5_nr_content_2:last-child {
                margin-bottom: 0px;
              }

              .backUls_box5_nr_content_3 {
                width: 100%;

                .backUls_box5_nr_content_3_view1 {
                  min-height: 2.82rem;
                  padding: 0.32rem;
                  box-sizing: border-box;
                  background: #fff6f1;
                  border-radius: 0.16rem;
                  border: 0.04rem solid #fed011;
                  position: relative;
                  margin-bottom: 0.48rem;

                  h1 {
                    width: 100%;
                    display: flex;
                    font-size: 0.28rem;
                    font-family: PingFang SC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #f97226;
                    line-height: 0.36rem;

                    span {
                      color: #ff8f03;
                      margin-left: 0.2rem;
                      font-weight: 500;
                      font-size: 0.24rem;
                    }
                  }

                  ul {
                    width: 100%;
                    margin-top: 0.24rem;
                    padding-left: 0.48rem;
                    box-sizing: border-box;

                    li {
                      width: 100%;
                      font-size: 0.24rem;
                      font-family: PingFang SC-Semibold, PingFang SC;
                      color: #666666;
                      line-height: 0.28rem;
                      text-align: left;
                      list-style: disc;
                      font-weight: 400;
                      margin-bottom: 0.24rem;
                    }
                  }

                  .jinpai {
                    width: 1.66rem;
                    position: absolute;
                    right: -0.35rem;
                    bottom: 0;

                    img {
                      width: 100%;
                    }
                  }

                  .jiangbei {
                    width: 1.66rem;
                    position: absolute;
                    right: -0.35rem;
                    bottom: -0.1rem;

                    img {
                      width: 100%;
                    }
                  }
                }

                .bo_color {
                  background: #fffce8 !important;
                  border: 0.04rem solid #fed011 !important;
                }

                .bo_color2 {
                  background: #f9fcff !important;
                  border: 0.04rem solid #d1e1ee !important;
                }
                .bo_color3 {
                  background: #fff4ee !important;
                  border: 0.04rem solid #fa772f !important;
                }
              }

              .backUls_box5_nr_content_3:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }

        .backUls_box6 {
          width: 100%;
          padding: 0.32rem;
          padding-top: 1.32rem;
          box-sizing: border-box;
          background: #ff782f;
        }
      }
    }
    .fontBottom {
      height: 1rem;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      // padding: 0.16rem 0.32rem 0.1rem 0.32rem;
      box-sizing: border-box;
      background: #ff782f;
      // // background:#D0D0D0;
      // backdrop-filter: blur(15px);
      // -webkit-backdrop-filter: saturate(180%) blur(15px);

      // background: url('~@/assets/image/icon/bottom_btn.png') no-repeat;
      // background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-height: 0.9rem;
      }

      span {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        font-size: 0.42rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .datumBox {
    width: 90vw;
    max-width: 600px;
    // width: 330px;
    // height: 308px;
    padding-top: 24px;
    padding-bottom: 24px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;

    .titleH1 {
      width: 100%;
      font-size: 18px;
      color: #ff5a25;
      font-weight: 600;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 11px;

      .right_icon {
        display: flex;
        align-items: center;
        div:nth-child(3) {
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
        div:nth-child(2) {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
        div:first-child {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
      }

      .left_icon {
        display: flex;
        align-items: center;
        div:first-child {
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
        div:nth-child(2) {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
        div:nth-child(3) {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
      }
    }

    .stepBox {
      width: 55%;
      margin: auto;
      position: relative;

      .stepUls {
        width: 100%;
        height: 30px;
        display: flex;

        .xian {
          width: 50%;
          height: 100%;
          position: relative;
        }

        .active {
          position: relative;
        }

        .active:after {
          content: ' ';
          width: 100%;
          height: 2.5px;
          background: #f5f5f5;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        .active2:after {
          content: ' ';
          width: 100%;
          height: 2.5px;
          background: #ff5a25;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }

      ul {
        width: 100%;
        height: 30px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #ff5a25;
          position: relative;

          div {
            position: absolute;
            bottom: -10%;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #cccccc;
          }
        }

        .active {
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background: url('~@/assets/image/fusai/dui.png') no-repeat;
          background-size: 100%;
        }
      }
    }

    .stepText {
      width: 70%;
      margin: auto;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #cccccc;
      }
      div:nth-child(1) {
        margin-left: 9%;
      }

      div:nth-child(2) {
        margin-left: 16%;
      }

      div:nth-child(3) {
        margin-left: 10%;
      }
      .active {
        color: #de2810 !important;
      }
    }

    .datumCentent {
      width: 80%;
      margin: auto;
      margin-top: 15px;

      .tab {
        width: 100%;
        text-align: left;
        margin-bottom: 10px;

        .prompt {
          height: 25px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #4d4d4d;
          line-height: 10px;
          // margin-bottom: 12px;
          // padding-left: 13px;
          // box-sizing: border-box;
          display: flex;

          div {
            margin-right: 11px;
            text-align: center;
            line-height: 20px;
            color: #999999;
          }

          .highlight {
            color: #4d4d4d !important;
            position: relative;
          }

          .highlight:after {
            content: ' ';
            width: 90%;
            height: 1px;
            background: #4d4d4d;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }

          span {
            font-size: 9.8px !important;
            color: #de2810;
            margin-left: 5px;
          }
        }

        .lis {
          width: 100%;
          height: 34px;
          line-height: 34px;
          background: #f5f5f5;
          display: flex;
          justify-content: space-between;
          border-radius: 6px;

          .lisLife {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4a4a4a;
            box-sizing: border-box;
            width: 90px;
            padding-left: 10px;
            img {
              width: 16px;
              height: 11px;
              margin-right: 5px;
            }
          }

          .lisRight {
            width: calc(100% - 95px);
            height: 100%;
            display: flex;
            input {
              font-size: 16px;
              width: 100%;
              height: 34px;
              padding-left: 3px;
              box-sizing: border-box;
              border: none;
              background: #f5f5f5;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
            }
            input::-webkit-input-placeholder {
              color: #999999;
            }
            /* 使用webkit内核的浏览器 */
            input:-moz-placeholder {
              color: #999999;
            }
            /* Firefox版本4-18 */
            input::-moz-placeholder {
              color: #999999;
            }
            /* Firefox版本19+ */
            input:-ms-input-placeholder {
              color: #999999;
            } /* IE浏览器 */
          }
        }

        .row-input {
          display: flex;
          align-items: center;
          font-size: 0px;
          margin-bottom: 12px;
        }
        .input-name {
          width: 25%;
          // height: 30px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #4d4d4d;
          padding-left: 13px;
        }
        .promptInput {
          width: 100%;
          // width: 75%;
          height: 34px;
          box-sizing: border-box;
          background: #f5f5f5;
          border-radius: 3px 3px 3px 3px;
          display: flex;
          align-items: center;
          input {
            width: 100%;
            height: 100%;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            background: #f5f5f5;
            border-radius: 3px 3px 3px 3px;
            border: none;
            padding-left: 13px;
            box-sizing: border-box;
          }

          input::-webkit-input-placeholder {
            color: #999999;
          }
          /* 使用webkit内核的浏览器 */
          input:-moz-placeholder {
            color: #999999;
          }
          /* Firefox版本4-18 */
          input::-moz-placeholder {
            color: #999999;
          }
          /* Firefox版本19+ */
          input:-ms-input-placeholder {
            color: #999999;
          } /* IE浏览器 */
        }

        .promptInput2 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          // width: 75%;
          height: 34px;
          line-height: 34px;
          box-sizing: border-box;
          background: #f5f5f5;
          border-radius: 6px 6px 6px 6px;
          // margin-bottom: 12px;
          span {
            width: 100%;
            height: 100%;
          }
          .span_tw {
            color: rgb(153, 153, 153) !important;
          }
          .promptInputValue {
            width: 100%;
            height: 100%;
            padding-left: 13px;
            box-sizing: border-box;
            border: none;
            background: #f5f5f5;
            border-radius: 6px 6px 6px 6px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
          }
        }

        .promptInput3 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          background-color: #f7f8fa;
          height: 130px;
          border: 3px dotted #f5f5f5;
          position: relative;

          .wonderful {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #4d4d4d;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50px;
              margin-bottom: 30px;
            }
          }

          .videoYulan {
            width: 100%;
            height: 100%;
            z-index: 999999;
            position: fixed;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            video {
              width: 80%;
              // object-fit: cover;
              height: 100%;
            }

            .guanbu {
              position: absolute;
              top: 5%;
              right: 5%;
              width: 20px;
              height: 20px;
              color: #fff;
              font-size: 12px;
              z-index: 999;
              border-radius: 50px;
              border: 1px solid #fff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .videoYulan2 {
            position: absolute;
            bottom: 3px;
            right: 0px;
            left: 0px;
            margin: auto;
            font-size: 12px;
            color: #ff5a25;
            text-align: center;
            width: 100%;
          }
        }
      }

      .tabTis {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #4d4d4d;
        line-height: 20px;
        margin-bottom: 50px;

        img {
          width: 30px;
          height: 30px;
          margin-left: 10px;
        }
      }
      .tabTis_1 {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #4d4d4d;
        line-height: 20px;
        // margin-bottom: 50px;

        img {
          width: 30px;
          height: 30px;
          margin-left: 10px;
        }
      }
      .view_guide {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff5a25;
        text-decoration: underline;
      }
      .but {
        width: 140px;
        height: 40px;
        background: url('~@/assets/image/but.png') no-repeat;
        background-size: 100%;
        text-align: center;
        line-height: 36px;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        margin: auto;
        margin-top: 20px;
      }

      .but2 {
        width: 140px;
        height: 30px;
        text-align: center;
        font-size: 14px;
        color: #4d4d4d;
        margin: auto;
        margin-top: 10px;
      }
    }
  }
}
.newShortBox {
  width: 330px;
  // height: 308px;
  padding-top: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;

  .list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .lis {
      width: 100%;
      margin-bottom: 20px;

      img {
        width: 149px;
      }
    }

    .lis:last-child {
      margin-bottom: 0px !important;
    }
    .lisText2 {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin: auto;
      font-size: 14px;

      div {
        p {
          margin-bottom: 10px;
        }
      }

      span {
        color: #ff5a25;
      }
    }

    .lisCenten {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ulst {
      width: 86%;
      margin: auto;

      ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 3px 3px 3px 3px;

        li {
          width: 33.3%;
          text-align: center;
          font-size: 12px;
        }
        li:nth-child(2) {
          width: 30% !important;
        }
      }

      ul:nth-child(1) {
        background: #ff5a25;
        height: 30px;
        line-height: 30px;

        li {
          height: 100%;
          color: #fff;
        }
      }

      ul:nth-child(2) {
        background: #feffeb;
        font-size: 9.8px !important;
        line-height: 20px;
        margin-top: 10px;
        margin-bottom: 20px;

        li {
          color: #4e4e4e;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .lisText {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #4e4e4e;
      line-height: 24px;
      padding-right: 25px;
      padding-left: 25px;
      box-sizing: border-box;
    }

    .but {
      width: 140px;
      height: 40px;
      background: url('~@/assets/image/but.png') no-repeat;
      background-size: 100%;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      margin: auto;
    }
  }
}
.show-link {
  color: #f97226;
  font-weight: 400 !important;
  text-decoration: underline;
}

.image-container {
  height: 78vh;
  overflow-y: auto;
}
.van-dialog {
  top: 54%;
  background-color: #000;
}

.tables {
  position: relative;
  width: 100%;
  background-color: #fff;
  border-radius: 0.16rem;
  padding: 10px;
  box-sizing: border-box;
  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 0px;
    border: 1px solid #fa772f;
    font-size: 16px;
    line-height: 22px;
    font-family: PingFang SC-Semibold, PingFang SC;
    color: #666666;
    th {
      border: 1px solid #fa772f;
      padding: 10px;
      font-weight: 600;
    }
    td {
      border: 1px solid #fa772f;
      padding: 10px;
      vertical-align: middle;
    }
  }
}
</style>
